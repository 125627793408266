body{
  max-width: 1200px;
  margin: auto;
  /*min-height: 110vh;*/
  /*background-color: aquamarine;*/
}

body > div{
  /*background-color: #d7ffda;*/
  /*min-height: 110vh;*/
}

.ant-menu.ant-menu-inline-collapsed {
  display: none;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: 0px;
}





/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/


.code-box-demo {
  text-align: center;
}
.ant-layout-header,
.ant-layout-footer {
  height: auto;
  padding: 0;
  line-height: normal;
  /*color: #fff;*/
  /*background: #7dbcea;*/
}
/*[data-theme="dark"] .ant-layout-header {*/
/*  background: #6aa0c7;*/
/*}*/
[data-theme="dark"] .ant-layout-footer {
  background: #6aa0c7;
}
.ant-layout-footer {
  line-height: 1.5;
}
.ant-layout-sider {
  /*color: #fff;*/
  line-height: inherit;
  /*background: #3ba0e9;*/
}
[data-theme="dark"] .ant-layout-sider {
  background: #3499ec;
}
.ant-layout-content {
  /*min-height: 120px;*/
  /*color: #fff;*/
  /*line-height: 120px;*/
  /*background: rgba(16, 142, 233, 1);*/
}
[data-theme="dark"] .ant-layout-content {
  background: #107bcb;
}
> .code-box-demo > .ant-layout + .ant-layout {
  margin-top: 48px;
}


.ant-card{
  padding: 20px;
  margin: 10px;
}

.ant-card-meta-detail {
  padding-bottom: 5px;
}

.ant-card-body {
  padding: 15px 0 0 0;
}

.ant-card-meta-description{
  color: black;
  height: 44px;
}

.ant-carousel .slick-dots li.slick-active {
  width: 16px;
}

.ant-carousel .slick-dots li button{
  width: 10px;
  height: 10px;
  color: #f2f2f200;
  border-radius: 50%;
  border: black 1px solid;
}

fieldset{
  padding: 10px;
  border: 1px solid #ccc7c7;
  border-radius: 5px;
  margin: 20px 0;
}
legend{
  border: 1px solid #ccc7c7;
  width: auto;
  max-width: inherit;
  padding: 0 10px;
  margin: 0 ;
  border-radius: 5px;
}

.ant-menu-title-content label{
  height: 100%;
  width: 100%;
  display: block;
}

.ant-input-number-handler.ant-input-number-handler-up:hover {
  height: inherit !important;
}
.ant-input-number-handler-down:hover {
  height: inherit !important;
}

.ant-input-number-handler-wrap {
  border: none;
  max-width: 0px;
}

.ant-input-number-handler.ant-input-number-handler-up::before {
  content: "+";
  color: green;
  font-size: 23px;
  line-height: 29px;
}

.ant-input-number-handler.ant-input-number-handler-down::before {
  content: "–";
  color: red;
  font-size: 23px;
  line-height: 29px;
}

.ant-input-number-handler.ant-input-number-handler-up {
  position: absolute;
  right: 62px;
  height: 100%;
  line-height: inherit;
  width: 20px;
  top: 0px;
}

.ant-input-number-handler.ant-input-number-handler-up > span {
  display: none;
}

.ant-input-number-handler-wrap {
  opacity: 1;
}

.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: inherit;
}

.ant-input-number-handler.ant-input-number-handler-down {
  position: absolute;
  left: 6px;
  border: none;
  height: inherit;
  width: 20px;
  top: -2px;

}

.ant-input-number-handler.ant-input-number-handler-down > span {
  display: none;
}

.ant-input-number-handler-wrap {
  opacity: 1;
}










@media (max-width: 770px) {
  .ant-input-number-handler.ant-input-number-handler-up {
    top: -3px;
  }
  .ant-input-number-handler.ant-input-number-handler-down {
    top: -6px;
  }
}

@media (min-width: 611px) {
  .ant-menu.ant-menu-inline-collapsed {
    display: block;
  }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    display: flex;
    align-items: center;
    transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
    padding-left: 24px;
    padding-right: 10px;
  }
}