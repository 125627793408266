.formControls.error input,
.formControls.error textarea{
    border: 3px solid red;
}

.formControls.error span{
    color: red;
}

.formControls{
    display: inline;
}
.formControls div{
    display: inline;
}