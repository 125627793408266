.captchaDiv{
    display: block;
}

.noCaptcha{
    display: none;
}

.error{
    color: red;
}

.noError{
    display: none;
}

.sc{
    color: green;
}

.form_container{
    text-align: center;
    padding: 10px;
}

.form_container h1 {
    font-size: 30px;
    margin-bottom: 0;
}


.block_header{
    /*display: flex;*/
    /*justify-content: space-between;*/
    font-size: 25px;
    padding: 5px;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    width: 100%;
}

.total{
    font-weight: bold;
    font-size: 25px;
    color: #1fb505;
    top: 4px;
    position: relative;
}

.error{
    color: red;
    font-weight: bold;
    transition: opacity 2s;
    opacity: 0
}

.error_show{
    opacity: 1;
}
